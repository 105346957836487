import React, {useRef} from 'react';
import PropTypes from 'prop-types';

/**
 * FileInput Component to handle file selection and drag & drop.
 * - Displays the file name if a file is selected.
 * - Allows selecting a file using a button or by dragging & dropping.
 *
 * @param file - The selected file object.
 * @param onDrop - Function to handle file drop event.
 * @param onSelect - Function to handle file selection event by button.
 * @param accept - The file types to accept (e.g., 'image/*', '.pdf', 'audio/*', etc.).
 * @returns {Element} - FileInput element.
 */
export default function FileInput({file, onDrop, onSelect, accept}) {
    const fileInputRef = useRef(null);
    const dropAreaRef = useRef(null);

    return (
        <div className="flex items-center overflow-hidden rounded border border-gray-300">
            <div
                className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap px-2.5 py-2 text-sm text-gray-500 focus:outline-none"
                ref={dropAreaRef}
                onDrop={onDrop}
                onDragOver={(e) => {
                    e.preventDefault();
                }}>
                {file?.name || 'Drag & drop or select...'}
            </div>
            <input
                ref={fileInputRef}
                type="file"
                className="hidden"
                onChange={onSelect}
                accept={accept}
            />
            <button
                className="whitespace-nowrap bg-purple-500 px-4 py-2 text-sm text-white transition-colors hover:bg-purple-600 focus:outline-none active:bg-purple-600"
                onClick={() => {
                    fileInputRef.current.click();
                }}>
                Select File
            </button>
        </div>
    );
}

FileInput.propTypes = {
    file: PropTypes.object,
    onDrop: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    accept: PropTypes.string,
};

FileInput.defaultProps = {
    file: null,
    onDrop: () => {},
    onSelect: () => {},
    accept: '*',
};
