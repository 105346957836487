import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PaginatedTable, Table } from "../components/Table";
import Icon from "../components/Icon";
import FileInput from "../components/FileInput";
import Select from "react-select";
import AuditConfig from "./AuditConfig";
import _ from "lodash";
import Checkbox from "../components/Checkbox";
import { API_URL } from '../constants';

/**
 * Application Stages, used to control the flow of the application.
 */
const Stages = {
  LOADING_EMPLOYERS_AND_BENEFITS: 0,
  SELECT_BENEFITS: 1,
  BENEFITS_SELECTED: 2,
  ACQUIRING_BENEFIT_ENROLLMENTS: 3,
  BENEFIT_ENROLLMENTS_ACQUIRED: 4,

  SELECT_MEMBERSHIP_LIST_FILE: 5,
  MEMBERSHIP_LIST_FILE_SELECTED: 6,
  UPLOADING_MEMBERSHIP_LIST: 7,
  MEMBERSHIP_LIST_UPLOADED: 8,

  LOADING_AUDIT_SETTINGS: 9,
  CONFIGURE_AUDIT_SETTINGS: 10,
  AUDITING: 11,
  AUDIT_COMPLETED: 12,
};

export default function Audit({ scrollRef }) {
  const { auditId } = useParams();
  const [stage, setStage] = useState(Stages.LOADING_EMPLOYERS_AND_BENEFITS);

  const [employers, setEmployers] = useState({});
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [benefits, setBenefits] = useState({});
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [benefitSelectionOptions, setBenefitSelectionOptions] = useState([]);
  const [policyholdersOnly, setPolicyholdersOnly] = useState(false);
  const [enrollmentsPreview, setEnrollmentsPreview] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFilePreview, setUploadedFilePreview] = useState(null);

  const [configData, setConfigData] = useState([]);
  const [configOptions, setConfigOptions] = useState([]);
  const [auditError, setAuditError] = useState(null);

  const [results, setResults] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);

  /**
   * Audit the uploaded file against the selected benefits.
   */
  const handleAudit = async () => {
    setStage(Stages.AUDITING);

    const data = { config: configData };

    try {
      const response = await axios.post(`${API_URL}/api/audit/${auditId}/run`, data);
      setResults(response.data);
      setStage(Stages.AUDIT_COMPLETED);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setAuditError(error.response.data.message);
      } else {
        console.error("An unexpected error occurred:", error);
      }
      setStage(Stages.CONFIGURE_AUDIT_SETTINGS);
    }
  };

  /**
   * Define benefits to audit against.
   */
  const handleAcquireBenEnrollments = async () => {
    setStage(Stages.ACQUIRING_BENEFIT_ENROLLMENTS);

    try {
      const data = {
        benefit_ids: selectedBenefits,
        policyholders_only: policyholdersOnly,
      };
      const response = await axios.post(
        `${API_URL}/api/audit/${auditId}/fetch-ben-enrollments`,
        data
      );

      setEnrollmentsPreview(response.data);
      setStage(Stages.BENEFIT_ENROLLMENTS_ACQUIRED);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      setStage(Stages.SELECT_BENEFITS);
    }
  };

  /**
   * Upload the selected Membership List CSV file.
   */
  const handleUploadMembershipList = async () => {
    if (!selectedFile) return;
    setStage(Stages.UPLOADING_MEMBERSHIP_LIST);

    try {
      const response = await axios.post(
        `${API_URL}/api/audit/${auditId}/upload-membership-list`,
        selectedFile
      );

      setUploadedFilePreview(response.data);
      setStage(Stages.MEMBERSHIP_LIST_UPLOADED);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      setStage(Stages.SELECT_MEMBERSHIP_LIST_FILE);
    }
  };

  /**
   * Start configuration of the audit settings.
   */
  const handleConfigure = async () => {
    setStage(Stages.LOADING_AUDIT_SETTINGS);

    try {
      const response = await axios.post(`${API_URL}/api/audit/${auditId}/configure`, {});

      setConfigData(response.data.sections);
      setConfigOptions(response.data.options);

      setStage(Stages.CONFIGURE_AUDIT_SETTINGS);
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      setStage(Stages.MEMBERSHIP_LIST_UPLOADED);
    }
  };

  /**
   * Download the full audit report as a CSV file.
   */
  const handleDownloadReport = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/audit/${auditId}/download-report`,
        {
          responseType: "blob",
        }
      );

      // noinspection JSCheckFunctionSignatures
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "audit_results.xlsx"); // File name
      document.body.appendChild(link);
      // noinspection JSUnresolvedReference
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the audit file:", error);
    }
  };

  /**
   * Fetch the list of employers & benefits.
   * This populates the employer & benefit selection dropdown inputs.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both employers and benefits concurrently
        const [employerResponse, benefitResponse] = await Promise.all([
          axios.get(`${API_URL}/api/employers`),
          axios.get(`${API_URL}/api/benefits`),
        ]);

        // Set employers data
        setEmployers(employerResponse.data);

        // Set benefits data and map the benefit selection options
        setBenefits(benefitResponse.data);
        setBenefitSelectionOptions(
          Object.values(benefitResponse.data).map((b) => ({
            value: b.id,
            label: `(${b.id}) ${b.name}`,
          }))
        );

        // Log or trigger any action once both are completed
        setStage(Stages.SELECT_BENEFITS);
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        setStage(Stages.LOADING_EMPLOYERS_AND_BENEFITS);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, [auditId]);

  /**
   * Keep scroll at the bottom of the page.
   */
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [stage, selectedResult, scrollRef]); // auditUpdates

  /**
   * Reset the application state when the stage changes.
   */
  useEffect(() => {
    if (stage < Stages.SELECT_BENEFITS) {
      setSelectedEmployers([]);
      setSelectedBenefits([]);
      setBenefitSelectionOptions([]);
    }

    if (stage < Stages.SELECT_MEMBERSHIP_LIST_FILE) {
      setSelectedFile(null);
    }

    if (stage < Stages.MEMBERSHIP_LIST_UPLOADED) {
      setUploadedFilePreview(null);
      setConfigData([]);
      setConfigOptions([]);
    }

    if (stage < Stages.AUDIT_COMPLETED) {
      //setAuditUpdates([]);
      setSelectedResult(null);
      setResults(null);
    }
  }, [stage]);

  // noinspection JSCheckFunctionSignatures
  return (
    <>
      <h1 className="mb-4 text-xl font-medium text-gray-800">
        Membership Lists vs. Ben Enrollments
      </h1>
      {stage >= Stages.LOADING_EMPLOYERS_AND_BENEFITS && (
        <div className="mb-8 w-full rounded border border-gray-300 bg-white p-8">
          <h1 className="text-lg font-semibold">Configure Ben Side</h1>
          <p className="mb-4 text-sm text-gray-500">
            These benefits could contain the exact enrollments that need to be
            audited against the uploaded membership list.
          </p>

          {/* Select Employers */}
          <div className="mb-4">
            <h2 className="mb-1 text-sm font-medium text-gray-800">
              Select Employers
            </h2>
            <Select
              className="mb-2 text-sm text-gray-800"
              placeholder={
                stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS
                  ? "Loading employers..."
                  : "Select employers..."
              }
              isLoading={stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS}
              isMulti
              value={selectedEmployers}
              onChange={(selected) => {
                setSelectedEmployers(selected);

                // noinspection JSUnresolvedReference
                const employerIds = new Set(selected.map((e) => e.value));
                setBenefitSelectionOptions(
                  Object.values(benefits)
                    .filter(
                      (b) =>
                        employerIds.size === 0 || employerIds.has(b.employer_id)
                    )
                    .map((b) => ({
                      value: b.id,
                      label: `(${b.id}) ${b.name}`,
                    }))
                );
              }}
              options={Object.values(employers).map((e) => ({
                value: e.id,
                label: `(${e.id}) ${e.name}`,
              }))}
            />

            {selectedEmployers.length > 0 && (
              <Table
                headers={["employer_id", "employer_name"]}
                rows={selectedEmployers.map((item) => ({
                  employer_id: employers[item.value].id,
                  employer_name: employers[item.value].name,
                }))}
              />
            )}
          </div>

          {/* Select Benefits */}
          <div className="mb-4">
            <h2 className="mb-1 text-sm font-medium text-gray-800">
              Select Benefits
            </h2>
            <Select
              className="mb-2 text-sm text-gray-800"
              placeholder={
                stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS
                  ? "Loading benefits..."
                  : "Select benefits..."
              }
              isLoading={stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS}
              isMulti
              value={selectedBenefits}
              onChange={(selected) => {
                setSelectedBenefits(selected);
                setStage(
                  selected.length > 0
                    ? Stages.BENEFITS_SELECTED
                    : Stages.SELECT_BENEFITS
                );
              }}
              options={benefitSelectionOptions}
            />

            {selectedBenefits.length > 0 && (
              <Table
                headers={["benefit_id", "benefit_name"]}
                rows={selectedBenefits.map((item) => ({
                  benefit_id: benefits[item.value].id,
                  benefit_name: benefits[item.value].name,
                }))}
              />
            )}
          </div>

          <div className="mb-4">
            <Checkbox
              label="Policyholders Only"
              value={policyholdersOnly}
              description="Only include policyholders on the Ben Enrollments."
              onChange={(checked) => setPolicyholdersOnly(checked)}
            />
          </div>

          {/* Preview Button */}
          {stage >= Stages.BENEFITS_SELECTED && (
            <div className="mb-4">
              <button
                className="block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
                onClick={handleAcquireBenEnrollments}
              >
                <div className="inline-flex items-center gap-x-1">
                  <p>
                    {stage === Stages.ACQUIRING_BENEFIT_ENROLLMENTS
                      ? "Loading..."
                      : "Preview"}
                  </p>
                  <Icon
                    icon={
                      stage === Stages.ACQUIRING_BENEFIT_ENROLLMENTS
                        ? "clock"
                        : "next"
                    }
                  />
                </div>
              </button>
            </div>
          )}

          {/* Benefit Enrollments Preview */}
          {stage >= Stages.BENEFIT_ENROLLMENTS_ACQUIRED && (
            <div className="mb-4">
              <PaginatedTable
                headers={enrollmentsPreview.headers}
                rows={enrollmentsPreview.rows}
                rowsPerPage={5}
              />
            </div>
          )}

          {/* Next */}
          {stage >= Stages.BENEFIT_ENROLLMENTS_ACQUIRED && (
            <button
              className="block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
              onClick={() => setStage(Stages.SELECT_MEMBERSHIP_LIST_FILE)}
            >
              <div className="inline-flex items-center gap-x-1">
                <p>Next</p>
                <Icon icon="next" />
              </div>
            </button>
          )}
        </div>
      )}

      {/* Configure Provider Side */}
      {stage >= Stages.SELECT_MEMBERSHIP_LIST_FILE && (
        <div className="mb-8 w-full rounded border border-gray-300 bg-white p-8">
          <h1 className="text-lg font-semibold">Configure Provider Side</h1>
          <p className="mb-4 text-sm text-gray-500">
            Upload a Membership List CSV & map columns to audit against the
            benefits selected above.
          </p>

          {/* Upload file */}
          <FileInput
            file={selectedFile}
            accept=".csv"
            onDrop={(e) => {
              e.preventDefault();
              if (e.dataTransfer.files.length > 0)
                setSelectedFile(e.dataTransfer.files[0]);
              setStage(Stages.MEMBERSHIP_LIST_FILE_SELECTED);
            }}
            onSelect={(e) => {
              if (e.target.files.length > 0) setSelectedFile(e.target.files[0]);
              setStage(Stages.MEMBERSHIP_LIST_FILE_SELECTED);
            }}
          />

          {/* Upload button */}
          {stage >= Stages.MEMBERSHIP_LIST_FILE_SELECTED && (
            <button
              className="my-4 block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
              onClick={handleUploadMembershipList}
              disabled={stage === Stages.UPLOADING_MEMBERSHIP_LIST}
            >
              <div className="inline-flex items-center gap-x-2">
                <Icon icon="upload" />
                <p>
                  {stage === Stages.UPLOADING_MEMBERSHIP_LIST
                    ? "Uploading..."
                    : "Upload"}
                </p>
              </div>
            </button>
          )}

          {/* Uploaded File Preview */}
          {stage >= Stages.MEMBERSHIP_LIST_UPLOADED && (
            <div className="mb-4">
              <PaginatedTable
                headers={uploadedFilePreview.headers}
                rows={uploadedFilePreview.rows}
                rowsPerPage={5}
              />
            </div>
          )}

          {/* Next */}
          {stage >= Stages.MEMBERSHIP_LIST_UPLOADED && (
            <button
              className="block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
              onClick={handleConfigure}
              disabled={stage === Stages.LOADING_AUDIT_SETTINGS}
            >
              <div className="inline-flex items-center gap-x-1">
                <p>
                  {stage === Stages.LOADING_AUDIT_SETTINGS
                    ? "Loading..."
                    : "Next"}
                </p>
                <Icon
                  icon={
                    stage === Stages.LOADING_AUDIT_SETTINGS ? "clock" : "next"
                  }
                />
              </div>
            </button>
          )}
        </div>
      )}

      {/* Configuration */}
      {stage >= Stages.CONFIGURE_AUDIT_SETTINGS && (
        <div className="mb-8 w-full rounded border border-gray-300 bg-white p-8">
          <h1 className="text-lg font-semibold">Audit Settings</h1>
          <p className="text-sm text-gray-500">
            Provide any information that the membership list may contain below &
            map columns and values accordingly.
          </p>
          <p className="mb-4 text-sm text-gray-500">
            The more information the tool receives, the better the audit results
            will be.
          </p>

          {/* Config List */}
          <AuditConfig
            data={configData}
            onChange={(path, value) =>
              setConfigData(_.set(_.cloneDeep(configData), path, value))
            }
            options={configOptions}
          />

          {/* Audit button */}
          <button
            className="my-4 block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
            onClick={handleAudit}
            disabled={stage === Stages.AUDITING}
          >
            <div className="inline-flex items-center gap-x-2">
              <Icon icon="audit" />
              <p>{stage === Stages.AUDITING ? "Auditing..." : "Start Audit"}</p>
            </div>
          </button>

          {/* Audit Error */}
          {auditError && (
            <div className="mb-4 rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              <p>{auditError}</p>
            </div>
          )}

          {/* Updates List with Timeline */}
          {/* <ol className="border-l-2 border-gray-300 pl-4">
                        {auditUpdates.map((update, index) => (
                            <li key={index}>
                                <div className="inline-flex items-center gap-x-2 p-2 text-sm text-gray-500">
                                    <Icon icon={update.icon} />
                                    <p>{update.message}</p>
                                </div>
                            </li>
                        ))}
                    </ol> */}
        </div>
      )}

      {/* Audit Results */}
      {stage >= Stages.AUDIT_COMPLETED && (
        <div className="block border-t border-gray-300">
          <h1 className="my-4 text-lg font-semibold">Audit Results</h1>

          {/* Download Full Report */}
          <button
            className="my-4 block w-full rounded border border-transparent bg-green-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-green-600 focus:outline-none active:bg-green-600"
            onClick={handleDownloadReport}
          >
            <div className="inline-flex items-center gap-x-2">
              <Icon icon="download" />
              <p>Download Full Report (.XLSX)</p>
            </div>
          </button>

          {/* Results */}
          <div className="mb-8 grid grid-cols-3 gap-6">
            {results.map(({ title, icon, data }, index) => (
              <div
                key={index}
                className="rounded border border-gray-300 bg-white p-4"
              >
                <div className="mb-4 flex items-center">
                  <div className="mr-4 rounded-full bg-blue-100 p-3 text-blue-500">
                    <Icon icon={icon} />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-600">{title}</p>
                    <p className="font-semibold text-gray-700">
                      {data["count"]}
                    </p>
                  </div>
                </div>
                <div className="border-t border-gray-300 pt-4">
                  <button
                    className="block w-full rounded border border-transparent bg-purple-500 p-2 text-center text-sm font-medium text-white transition-colors hover:bg-purple-600 focus:outline-none active:bg-purple-600"
                    onClick={() => setSelectedResult(data)}
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* View Selected Results */}
          {selectedResult && (
            <PaginatedTable
              rows={selectedResult["rows"]}
              headers={selectedResult["headers"]}
              rowsPerPage={10}
            />
          )}
        </div>
      )}
    </>
  );
}
