import Checkbox from '../components/Checkbox';
import Select from 'react-select';
import Icon from '../../src/components/Icon';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Main Component
export default function AuditConfig({data, onChange, options}) {
    // noinspection JSUnresolvedReference
    return (
        <>
            {data.map((section, sectionIndex) => (
                <div
                    className="mb-6"
                    key={sectionIndex}>
                    <div className="mb-1 flex items-center gap-x-2">
                        <h3 className="text-sm font-medium uppercase text-gray-800">{section.name}</h3>
                        <div className="h-px flex-grow bg-gray-300"></div>
                    </div>
                    <p className="mb-4 text-sm text-gray-500">{section.description}</p>

                    {section.entries.map((entry, entryIndex) => (
                        <div
                            className="mb-2 rounded border border-gray-300 p-3"
                            key={entryIndex}>
                            <Checkbox
                                label={entry.name}
                                value={entry.checked}
                                description={entry.description}
                                onChange={(checked) => onChange([sectionIndex, 'entries', entryIndex, 'checked'], checked)}
                            />

                            {entry.checked &&
                                entry.fields.map((field, fieldIndex) => (
                                    <div
                                        className="mt-2"
                                        key={fieldIndex}>
                                        {field.data_type === 'JSON' && (
                                            <div className="mb-2">
                                                <h3 className="mb-2 text-xs font-medium text-gray-800">Path:</h3>
                                                <Select
                                                    className="text-xs text-gray-800"
                                                    placeholder="Select path..."
                                                    value={field.json_path}
                                                    isClearable={true}
                                                    options={field.path_options}
                                                    onChange={(selected) => {
                                                        const updatedField = _.cloneDeep(data[sectionIndex].entries[entryIndex].fields[fieldIndex]);
                                                        updatedField.json_path = selected;

                                                        // noinspection JSUnresolvedReference
                                                        updatedField.value_map = field.path_values[selected.value];
                                                        onChange([sectionIndex, 'entries', entryIndex, 'fields', fieldIndex], updatedField);
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div className="grid grid-cols-3 items-center">
                                            <Select
                                                className="text-xs text-gray-800"
                                                placeholder="Select column..."
                                                value={field.column_map}
                                                isClearable={true}
                                                options={options.columns}
                                                onChange={(selected) => onChange([sectionIndex, 'entries', entryIndex, 'fields', fieldIndex, 'column_map'], selected)}
                                            />
                                            <div className="flex justify-center">
                                                <Icon icon="arrow-right" />
                                            </div>
                                            <div className="rounded border border-gray-300 p-2 text-xs font-medium text-gray-600">{field.json_path ? `${field.id} -> ${field.json_path.value}` : field.id}</div>
                                        </div>

                                        {field.column_map && field.value_map_required && (
                                            <div className="mt-2">
                                                <h3 className="mb-2 text-xs font-medium text-gray-800">Value Mapping:</h3>

                                                {Object.keys(field.value_map).map((target, targetIndex) => (
                                                    <div
                                                        className="mb-1 mt-2"
                                                        key={targetIndex}>
                                                        <div className="grid grid-cols-3 items-center">
                                                            <Select
                                                                isMulti
                                                                className="text-xs text-gray-800"
                                                                placeholder="Select values..."
                                                                value={field.value_map[target]}
                                                                options={options.values[field.column_map.value]}
                                                                onChange={(selected) => onChange([sectionIndex, 'entries', entryIndex, 'fields', fieldIndex, 'value_map', target], selected)}
                                                            />
                                                            <div className="flex justify-center">
                                                                <Icon icon="arrow-right" />
                                                            </div>
                                                            <div className="rounded border border-gray-300 p-2 text-xs font-medium text-gray-600">{target}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {field.data_type === 'DATE' && (
                                            <div className="mt-2">
                                                <h3 className="mb-2 text-xs font-medium text-gray-800">Year, Month & Day Ordering:</h3>
                                                <Select
                                                    isClearable={true}
                                                    className="text-xs text-gray-800"
                                                    placeholder="Specify order of date parts..."
                                                    required={true}
                                                    value={field.date_order}
                                                    options={field.date_order_options}
                                                    onChange={(selected) => onChange([sectionIndex, 'entries', entryIndex, 'fields', fieldIndex, 'date_order'], selected)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}

AuditConfig.propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
};
