import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import axios from "axios";

const AxiosInterceptor = ({ children }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (request) => {
        const accessToken = activeAccount.idToken;
        if (accessToken) {
          request.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return request;
      }
    );
    return () => axios.interceptors.request.eject(requestInterceptor);
  }, [activeAccount]);

  return children;
};

export default AxiosInterceptor;
