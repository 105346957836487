import {
  MsalProvider,
  AuthenticatedTemplate,
  useMsal,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./home/Home";
import { loginRequest } from "./authConfig";
import AxiosInterceptor from "./utils/axios-interceptor";

const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="App">
      <AuthenticatedTemplate>
        {activeAccount ? (
          <AxiosInterceptor>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Navigate to="/home/audit" />} />
                <Route path="/home/*" element={<Home />} />
              </Routes>
            </BrowserRouter>
          </AxiosInterceptor>
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <body class="h-screen bg-gray-100 flex items-center justify-center">
            <div class="bg-white p-8 rounded-lg shadow-lg w-96 text-center">
              <h1 class="text-2xl font-bold text-gray-800 mb-6">
                Ben Audit Tool
              </h1>
              <p class="text-gray-600 mb-4">Click below to sign in via SSO</p>
              <button
                className="block w-full rounded border border-transparent bg-gray-100 p-2 text-center text-sm font-medium text-black"
                onClick={handleRedirect}
                variant="primary"
              >
                Sign In
              </button>
            </div>
          </body>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App({ instance }) {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
}
