import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

const createAudit = async (navigate) => {
  try {
    const createResponse = await axios.post(`${API_URL}/api/audit/create`);
    const auditId = createResponse.data.audit_id;
    navigate(`/home/audit/${auditId}`, { replace: true });
  } catch (error) {
    console.error("Error creating session:", error);
  }
};

export default function AuditNew() {
  const navigate = useNavigate();
  useEffect(() => {
    createAudit(navigate);
  }, [navigate]);

  return null;
}
