import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox Component with label and value.
 *
 * @param label - Display value for the checkbox.
 * @param value - Boolean value of the checkbox.
 * @param description - Further description of what the checkbox does.
 * @param onChange - Function to handle checkbox change.
 * @returns {Element} - Checkbox element.
 */
export default function Checkbox({label, value, description, onChange}) {
    return (
        <div className="flex items-center gap-2">
            <input
                className="focus:shadow-outline-purple text-purple-600 focus:border-purple-400 focus:outline-none"
                type="checkbox"
                value={value}
                onChange={(e) => onChange(e.target.checked)}
            />
            <span className="text-xs font-medium uppercase text-gray-600">{label}</span>
            <span className="text-xs text-gray-400">•</span>
            <span className="text-xs text-gray-400">{description}</span>
        </div>
    );
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    description: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
    label: 'Unnamed',
    value: false,
    description: '',
    onChange: () => {},
};
