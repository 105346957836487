// icon:reload-circle-outline | Ionicons https://ionicons.com/ | Ionic Framework
// icon:delete-bin-5-line | Remix Icon https://remixicon.com/ | Remix Design
import React from 'react';

function IconCheck() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 512 512"
            fill="currentColor">
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256zm113-303L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
        </svg>
    );
}

function IconX() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 16 16"
            fill="currentColor">
            <path
                fillRule="evenodd"
                d="M2.343 13.657A8 8 0 1113.657 2.343 8 8 0 012.343 13.657zM6.03 4.97a.75.75 0 00-1.06 1.06L6.94 8 4.97 9.97a.75.75 0 101.06 1.06L8 9.06l1.97 1.97a.75.75 0 101.06-1.06L9.06 8l1.97-1.97a.75.75 0 10-1.06-1.06L8 6.94 6.03 4.97z"
            />
        </svg>
    );
}

function IconClock() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 512 512"
            fill="currentColor">
            <path d="M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0s256 114.6 256 256-114.6 256-256 256zm-24-392v136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
        </svg>
    );
}

function IconError() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
        </svg>
    );
}

function IconAudit() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 1024 1024"
            fill="currentColor">
            <path d="M296 250c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm184 144H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-48 458H208V148h560v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm440-88H728v-36.6c46.3-13.8 80-56.6 80-107.4 0-61.9-50.1-112-112-112s-112 50.1-112 112c0 50.7 33.7 93.6 80 107.4V764H520c-8.8 0-16 7.2-16 16v152c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V780c0-8.8-7.2-16-16-16zM646 620c0-27.6 22.4-50 50-50s50 22.4 50 50-22.4 50-50 50-50-22.4-50-50zm180 266H566v-60h260v60z" />
        </svg>
    );
}

function IconDownload() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 512 512"
            fill="currentColor">
            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32v242.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-32c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 104c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24z" />
        </svg>
    );
}

function IconUpload() {
    return (
        <svg
            className="h-3 w-3"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path d="M9 16v-6H5l7-7 7 7h-4v6H9m-4 4v-2h14v2H5z" />
        </svg>
    );
}

function IconArrowRight() {
    return (
        <svg
            className="h-5 w-5"
            fill="currentColor"
            viewBox="0 0 16 16">
            <path
                fillRule="evenodd"
                d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
            />
        </svg>
    );
}

function IconCaretLeft() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 15 15">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8.818 4.182a.45.45 0 010 .636L6.136 7.5l2.682 2.682a.45.45 0 11-.636.636l-3-3a.45.45 0 010-.636l3-3a.45.45 0 01.636 0z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function IconDoubleCaretLeft() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 15 15">
            <path
                stroke="currentColor"
                strokeLinecap="square"
                d="M6.5 9.5l-2-2 2-2m3 4l-2-2 2-2"
            />
        </svg>
    );
}

function IconCaretRight() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 15 15">
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M6.182 4.182a.45.45 0 01.636 0l3 3a.45.45 0 010 .636l-3 3a.45.45 0 11-.636-.636L8.864 7.5 6.182 4.818a.45.45 0 010-.636z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function IconDoubleCaretRight() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 15 15">
            <path
                stroke="currentColor"
                strokeLinecap="square"
                d="M8.5 9.5l2-2-2-2m-3 4l2-2-2-2"
            />
        </svg>
    );
}

function IconNext() {
    return (
        <svg
            className="h-5 w-5"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path d="M11.293 17.293l1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z" />
        </svg>
    );
}

function IconTool() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            viewBox="0 0 24 24">
            <path d="M14.7 6.3a1 1 0 000 1.4l1.6 1.6a1 1 0 001.4 0l3.77-3.77a6 6 0 01-7.94 7.94l-6.91 6.91a2.12 2.12 0 01-3-3l6.91-6.91a6 6 0 017.94-7.94l-3.76 3.76z" />
        </svg>
    );
}

function IconInfo() {
    return (
        <svg
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M11 10.98a1 1 0 112 0v6a1 1 0 11-2 0v-6zM12 6.051a1 1 0 100 2 1 1 0 000-2z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zM4 12a8 8 0 1016 0 8 8 0 00-16 0z"
                clipRule="evenodd"
            />
        </svg>
    );
}

function IconAdd() {
    return (
        <svg
            className="h-5 w-5"
            viewBox="0 0 512 512"
            fill="currentColor">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M256 112v288M400 256H112"
            />
        </svg>
    );
}

function IconDelete() {
    return (
        <svg
            className="h-5 w-5"
            viewBox="0 0 24 24"
            fill="currentColor">
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path d="M4 8h16v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8zm2 2v10h12V10H6zm3 2h2v6H9v-6zm4 0h2v6h-2v-6zM7 5V3a1 1 0 011-1h8a1 1 0 011 1v2h5v2H2V5h5zm2-1v1h6V4H9z" />
        </svg>
    );
}

export default function Icon({icon}) {
    switch (icon) {
        case 'check':
            return <IconCheck />;
        case 'x':
            return <IconX />;
        case 'clock':
            return <IconClock />;
        case 'audit':
            return <IconAudit />;
        case 'upload':
            return <IconUpload />;
        case 'download':
            return <IconDownload />;
        case 'arrow-right':
            return <IconArrowRight />;
        case 'caret-left':
            return <IconCaretLeft />;
        case 'double-caret-left':
            return <IconDoubleCaretLeft />;
        case 'caret-right':
            return <IconCaretRight />;
        case 'double-caret-right':
            return <IconDoubleCaretRight />;
        case 'next':
            return <IconNext />;
        case 'tool':
            return <IconTool />;
        case 'info':
            return <IconInfo />;
        case 'add':
            return <IconAdd />;
        case 'delete':
            return <IconDelete />;
        case 'error':
            return <IconError />;
        default:
            return <IconError />;
    }
}
