import React, { useRef } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Icon from "../components/Icon";
import logo from "../assets/logo.png";
import Audit from "./Audit";
import AuditNew from "./AuditNew";
import { useMsal } from "@azure/msal-react";

export default function Home() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const scrollRef = useRef(null);

  const handleSignOut = async () => {
    await instance.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
      },
      account: activeAccount,
    });
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* SideBar */}
      <aside className="w-64 flex-none border-r bg-black p-8">
        {/* Ben Logo */}
        <img src={logo} alt="Ben Logo" className="mb-4 h-auto w-1/2" />

        {/* Title */}
        <div className="flex gap-x-2">
          <h1 className="font-bold text-white">Audit Tool</h1>
        </div>

        {/* Divider */}
        <div className="mb-4 mt-2 border-b-2 border-white" />

        {/* Navigation */}
        <ul>
          <li>
            <Link
              to="/home/audit/new"
              className="flex items-center gap-x-4 p-2 text-sm font-semibold text-white transition-colors hover:text-gray-200 active:text-gray-300"
            >
              <Icon icon="add" />
              <span>New Audit</span>
            </Link>
          </li>
          <li>
            <Link
              to="/home/audit/new"
              className="flex items-center gap-x-4 p-2 text-sm font-semibold text-white transition-colors hover:text-gray-200 active:text-gray-300"
            >
              <Icon icon="info" />
              <span>View Audits</span>
            </Link>
          </li>
        </ul>

        {/* Divider */}
        <div className="mb-4 mt-2 border-b-2 border-white" />

        {/* User */}
        <div>
          <h2 className="text-white mb-2">{activeAccount.name}</h2>
          <button
            className="block w-full rounded border border-transparent bg-gray-100 p-1 text-center text-sm font-medium text-black"
            variant="primary"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
      </aside>

      {/* Content */}
      <main className="flex-grow bg-gray-100">
        <div className="h-full overflow-y-scroll" ref={scrollRef}>
          <div className="mx-auto max-w-6xl p-8">
            <Routes>
              <Route path="audit/new" element={<AuditNew />} />
              <Route
                path="audit/:auditId"
                element={<Audit scrollRef={scrollRef} />}
              />
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
}
