import Icon from './Icon';
import PropTypes from 'prop-types';
import {useMemo, useState} from 'react';

/**
 * Renders the base structure of a table, displaying rows of data with configurable headers.
 * (To use in both regular and paginated table components)
 *
 * Displays data in a tabular format where each row is mapped from the provided `rows`
 * and each header corresponds to a key in the row objects.
 * - If a cell value is `null` or an empty string, it displays an "Empty" placeholder.
 * - If a cell value is `true`, it displays a checkmark icon.
 * - If a cell value is `false`, it displays a cross icon.
 *
 * @param {Array<Object>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @returns {JSX.Element} A table element with rows and headers.
 */
function TableBase({rows, headers}) {
    return (
        <table className="text-overflow-ellipsis w-full overflow-hidden whitespace-nowrap">
            <thead>
                <tr className="border-b border-gray-300 bg-gray-50 text-left text-xs font-semibold uppercase tracking-wide text-gray-500">
                    {headers.map((header) => (
                        <th className="p-3">{String(header).replace(/_/g, ' ')}</th>
                    ))}
                </tr>
            </thead>
            <tbody className="divide-y bg-white">
                {rows.map((row, index) => (
                    <tr
                        key={index}
                        className="text-gray-700">
                        {headers.map((header) => (
                            <td
                                key={header}
                                className="p-3 text-xs">
                                {row[header] === null || row[header] === '' ? (
                                    <span className="rounded bg-gray-200 px-2 py-1 font-semibold leading-tight text-gray-700">Empty</span>
                                ) : row[header] === true ? (
                                    <Icon icon="check" />
                                ) : row[header] === false ? (
                                    <Icon icon="x" />
                                ) : (
                                    row[header]
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

TableBase.propTypes = {
    rows: PropTypes.array,
    headers: PropTypes.array.isRequired,
};

TableBase.defaultProps = {
    rows: [],
};

/**
 * Renders a table with the provided rows and headers.
 *
 * @param {Array<Object>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @returns {JSX.Element} A table element with rows and headers.
 */
export function Table({rows, headers}) {
    return (
        <div className="w-full overflow-hidden overflow-x-auto rounded border border-gray-300">
            <TableBase
                rows={rows}
                headers={headers}
            />
        </div>
    );
}

Table.propTypes = {
    rows: PropTypes.array,
    headers: PropTypes.array.isRequired,
};

Table.defaultProps = {
    rows: [],
};

/**
 * Renders a paginated table with the provided rows and headers.
 * Result will be displayed in a paginated table format with configurable rows per page.
 * Buttons are provided to navigate between pages.
 *
 * @param {Array<Object>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @param {Number} rowsPerPage - Number of rows to display per page.
 * @returns {JSX.Element} A table element with rows and headers.
 */
export function PaginatedTable({rows, headers, rowsPerPage}) {
    const [currentPage, setCurrentPage] = useState(1);

    // Total number of pages
    const totalPages = useMemo(() => {
        return Math.ceil(rows.length / rowsPerPage);
    }, [rows.length, rowsPerPage]);

    // Sliced data based on current page
    const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return rows.slice(startIndex, startIndex + rowsPerPage);
    }, [currentPage, rows, rowsPerPage]);

    // Pagination text, e.g. "Showing 1-10 of 100"
    const paginationText = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, rows.length);
        return `Showing ${startIndex + 1}-${endIndex} of ${rows.length}`;
    }, [currentPage, rows.length, rowsPerPage]);

    return (
        <div>
            <div className="w-full overflow-hidden overflow-x-auto rounded-t border border-gray-300">
                <TableBase
                    rows={currentData}
                    headers={headers}
                />
            </div>
            <div className="flex w-full items-center justify-between gap-4 rounded-b border-x border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-semibold text-gray-500">
                <div className="flex items-center">{paginationText}</div>
                <div className="flex items-center gap-x-1">
                    <button
                        className="focus:shadow-outline-purple p-1 focus:outline-none"
                        onClick={() => setCurrentPage(1)}
                        disabled={currentPage === 1}>
                        <Icon icon="double-caret-left" />
                    </button>
                    <button
                        className="focus:shadow-outline-purple p-1 focus:outline-none"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}>
                        <Icon icon="caret-left" />
                    </button>

                    <div className="w-24 text-center">{`Page ${currentPage} of ${totalPages}`}</div>

                    <button
                        className="focus:shadow-outline-purple p-1 focus:outline-none"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}>
                        <Icon icon="caret-right" />
                    </button>
                    <button
                        className="focus:shadow-outline-purple p-1 focus:outline-none"
                        onClick={() => setCurrentPage(totalPages)}
                        disabled={currentPage === totalPages}>
                        <Icon icon="double-caret-right" />
                    </button>
                </div>
            </div>
        </div>
    );
}

PaginatedTable.propTypes = {
    rows: PropTypes.array,
    headers: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number,
};

PaginatedTable.defaultProps = {
    rows: [],
    rowsPerPage: 10,
};
